import Token from "../asset/TOKENOMICS.webp";
import TokenText from "../asset/TOKENOMICS-TEXT.webp";
import wave from "../asset/wave.png";

export default function Tokenomics() {
  return (
    <>
      <div className="tokenomics">
        <img src={Token} alt="token" className="token-image" />
        <img src={TokenText} alt="token-text" className="token-text-image" />
      </div>
      <img src={wave} alt="wave" className="tokenomics-wave-image" />
    </>
  );
}
