import React, { useRef } from "react";
import myStory from "../asset/MY-STORY.png";

export default function Story() {
  const videoRef = useRef(null);

  return (
    <div className="story" id="story">
      <img src={myStory} alt="my-story" className="my-story" />

      <div className="story-grid">
        <Content title="My First Video">
          <video ref={videoRef} autoPlay loop muted playsInline>
            <source src={require("../asset/FirstVideo.mp4")} type="video/mp4" />
          </video>
        </Content>
        <img
          src={require("../asset/line1.png")}
          alt="line1"
          className="line1 line"
        />
        <Content title="100K - 1M on YouTube">
          <img
            src={require("../asset/heroImg.webp")}
            alt="100k-1m"
            className="story-img"
          />
        </Content>
        <img
          src={require("../asset/line2.png")}
          alt="line2"
          className="line2 line"
        />
        <Content title="My Best Video">
          <video ref={videoRef} autoPlay loop muted playsInline>
            <source src={require("../asset/BestVideo.mp4")} type="video/mp4" />
          </video>
        </Content>
        <img
          src={require("../asset/line3.png")}
          alt="line3"
          className="line3 line"
        />
        <img
          src={require("../asset/line1.png")}
          alt="line1"
          className="line1 line mob"
        />
        <Content title="5 Million on TikTok">
          <img
            src={require("../asset/5Million.jpeg")}
            alt="100k-1m"
            className="story-img"
          />
        </Content>
        <img
          src={require("../asset/line4.png")}
          alt="line4"
          className="line4 line"
        />
        <img
          src={require("../asset/line2.png")}
          alt="line2"
          className="line2 line mob"
        />
        <Content title="Creating $JULIA">
          <img
            src={require("../asset/heroImg.webp")}
            alt="100k-1m"
            className="story-img"
          />
        </Content>
      </div>
    </div>
  );
}

const Content = ({
  title,
  children,
}: {
  title: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className="story-content">
      <div className="title">{title}</div>
      {/* <p className="text">click here to read more</p> */}
      {children}
    </div>
  );
};
