import AboutMe from "../asset/AboutMe.webp";
import heroImg from "../asset/heroImg.webp";
import wave from "../asset/wave.png";

export default function About() {
  return (
    <>
      <div className="about" id="about">
        <div className="content">
          <img src={AboutMe} alt="yt" className="about-image" />
          <div className="about-text">
            <video autoPlay controls playsInline className="about-text-video">
              <source
                src={require("../asset/Instruction.mp4")}
                type="video/mp4"
              />
            </video>
            <div className="about-text-image">
              <p>
                Hey! I'm Julia Cardeal, a Brazilian tiktoker and youtuber! I
                began my TikTok in 2019 and have since amassed over 6 million
                followers creating POV videos.
              </p>
              <p>
                I have also recently reached the 1 Million subscribers milestone
                on my YouTube channel - and am waiting for my gold plaque to
                arrive!
              </p>
              <p>
                Now, I've decided to try my luck in Cryptocurrencies, launching
                my own coin $JULIA on pump.Fun!
              </p>
              <p>
                Make sure to tune into my TikTok live when I launch the coin -
                and play the minigame on this website to maybe even get a
                feature!
              </p>
            </div>
            <img src={heroImg} alt="hero" className="julia-image" />
          </div>
        </div>
      </div>
      <img src={wave} alt="wave" className="about-wave-image" />
    </>
  );
}
