import PumpIcon from "../asset/PumpLogo.webp";
import TiktokIcon from "../asset/tiktokLogo.webp";
import InstaIcon from "../asset/InstagramLogo.webp";
import YtIcon from "../asset/YouTubLogo.webp";
import { Links } from "../const/links";

export default function Footer() {
  return (
    <div className="footer">
      <div className="bar" />
      <div className="footer-content">
        <div className="social">
          <a href={Links.pump} target="_blank" rel="noreferrer">
            <div className="social-text">Pump.fun</div>
            <img src={PumpIcon} alt="pump-icon" className="social-icon" />
          </a>
          <a href={Links.tiktok} target="_blank" rel="noreferrer">
            <div className="social-text">tiktok</div>
            <img src={TiktokIcon} alt="tiktok-icon" className="social-icon" />
          </a>
          <a href={Links.insta} target="_blank" rel="noreferrer">
            <div className="social-text">Instagram</div>
            <img src={InstaIcon} alt="insta-icon" className="social-icon" />
          </a>
          <a href={Links.yt} target="_blank" rel="noreferrer">
            <div className="social-text">Youtube</div>
            <img src={YtIcon} alt="yt-icon" className="social-icon" />
          </a>
        </div>
        <div className="footer-text">
          BUY $JULIA NOW - 1 BILLION SUPPLY - LP BURNED - COMMUNITY COIN
        </div>
      </div>
    </div>
  );
}
