import createMe from "../asset/CREATE-ME-TEXT.webp";
import bgText from "../asset/backgrounds.png";
import clothing from "../asset/clothing-items.png";
import accessorries from "../asset/accessorries.png";
import CreateMeBoxes from "./CreateMeBoxes";
import CreateMeMultiBox from "./CreateMeMultiBox";
import { useRef, useState } from "react";

import accessories1 from "../asset/Accessories/1.png";
import accessories2 from "../asset/Accessories/2.png";
import accessories3 from "../asset/Accessories/3.png";
import accessories4 from "../asset/Accessories/4.png";
import accessories5 from "../asset/Accessories/5.png";
import accessories6 from "../asset/Accessories/6.png";
import accessories7 from "../asset/Accessories/7.png";

import clothing1 from "../asset/Clothing/1.png";
import clothing2 from "../asset/Clothing/2.png";
import clothing3 from "../asset/Clothing/3.png";
import clothing4 from "../asset/Clothing/4.png";
import clothing5 from "../asset/Clothing/5.png";
import clothing6 from "../asset/Clothing/6.png";
import clothing7 from "../asset/Clothing/7.png";

import bg1 from "../asset/Backgrounds/1.png";
import bg2 from "../asset/Backgrounds/2.png";
import bg3 from "../asset/Backgrounds/3.png";
import bg4 from "../asset/Backgrounds/4.png";
import bg5 from "../asset/Backgrounds/5.png";
import bg6 from "../asset/Backgrounds/6.png";
import bg7 from "../asset/Backgrounds/7.png";

import { toPng } from "html-to-image";

import character from "../asset/Character.png";

const accessoriesImages = [
  accessories1,
  accessories2,
  accessories3,
  accessories4,
  accessories5,
  accessories6,
  accessories7,
];

const clothingImages = [
  clothing1,
  clothing2,
  clothing3,
  clothing4,
  clothing5,
  clothing6,
  clothing7,
];

const bgsImages = [bg1, bg2, bg3, bg4, bg5, bg6, bg7];

export enum CreateMeVariants {
  BG = "bg",
  CLOTHING = "clothing",
  ACCESSORIES = "accessories",
}

export default function CreateMe() {
  const [createMeBoxes, setCreateMeBoxes] = useState([0, 0, 0]);
  const [accesoriesSelected, setAccesoriesSelected] = useState<number[]>([]);

  const elementRef = useRef<HTMLDivElement | null>(null);

  const htmlToImageConvert = () => {
    if (elementRef.current === null) return;

    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "my-julia.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="createMe">
      <img src={createMe} alt="create" className="createMe-img" />
      <div className="pad">
        <div className="container">
          <div>
            <div>
              <div className="main-box">
                <div ref={elementRef} style={{ height: "100%" }}>
                  <div
                    className="bg bg-image"
                    style={{
                      backgroundImage: `url(${bgsImages[createMeBoxes[0]]})`,
                    }}
                  />
                  <img src={character} alt="character" className="character" />
                  <div
                    className="clothing bg-image"
                    style={{
                      backgroundImage: `url(${
                        clothingImages[createMeBoxes[1]]
                      })`,
                    }}
                  />
                  {accesoriesSelected.map((index) => (
                    <div
                      key={index}
                      className="accessories bg-image"
                      style={{
                        backgroundImage: `url(${accessoriesImages[index]})`,
                        zIndex:
                          index === 5 || index === 6 || index == 3 ? 2 : 4,
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="save-image">
              <button onClick={htmlToImageConvert}>SAVE IMAGE</button>
            </div>
          </div>
          <div className="side-boxes">
            <div className="section">
              <img src={bgText} alt="bgText" className="text-img" />
              <CreateMeBoxes
                variant={CreateMeVariants.BG}
                activeIndex={createMeBoxes[0]}
                setActiveIndex={(index) =>
                  setCreateMeBoxes([index, createMeBoxes[1], createMeBoxes[2]])
                }
              />
            </div>
            <div className="section">
              <img src={clothing} alt="clothing" className="text-img" />
              <div className="boxes">
                <CreateMeBoxes
                  variant={CreateMeVariants.CLOTHING}
                  activeIndex={createMeBoxes[1]}
                  setActiveIndex={(index) =>
                    setCreateMeBoxes([
                      createMeBoxes[0],
                      index,
                      createMeBoxes[2],
                    ])
                  }
                />
              </div>
            </div>
            <div className="section">
              <img src={accessorries} alt="accessorries" className="text-img" />
              <div className="boxes">
                <CreateMeMultiBox
                  variant={CreateMeVariants.ACCESSORIES}
                  activeIndexes={accesoriesSelected}
                  setActiveIndex={(index) => {
                    if (accesoriesSelected.includes(index)) {
                      setAccesoriesSelected(
                        accesoriesSelected.filter((i) => i !== index)
                      );
                    } else {
                      setAccesoriesSelected([...accesoriesSelected, index]);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
