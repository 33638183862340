import wave from "../asset/wave.png";
import Julia from "../asset/Julia.webp";
import CreateMe from "./CreateMe";

export default function Grid() {
  return (
    <>
      <div className="grid">
        <img src={Julia} alt="julia" className="hero-image" />
        <CreateMe />
      </div>
      <img src={wave} alt="wave" className="wave-image" />
    </>
  );
}
