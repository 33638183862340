import "./App.css";
import Header from "./components/Header";
import Grid from "./components/Grid";
import About from "./components/About";
import Tokenomics from "./components/Tokenomics";
import Footer from "./components/Footer";
import Story from "./components/Story";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    toast(
      <div>
        I am doing a TikTok live celebrating the launch of my coin - join me
        there now! Click on TikTok icon on site
      </div>,
      {
        position: "bottom-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }
    );
  });

  return (
    <div className="wrapper">
      <Header />
      <Grid />
      <About />
      <Tokenomics />
      <Story />
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default App;
