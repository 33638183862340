import { CreateMeVariants } from "./CreateMe";

import accessories1 from "../asset/AccessoriesThumb/1.png";
import accessories2 from "../asset/AccessoriesThumb/2.png";
import accessories3 from "../asset/AccessoriesThumb/3.png";
import accessories4 from "../asset/AccessoriesThumb/4.png";
import accessories5 from "../asset/AccessoriesThumb/5.png";
import accessories6 from "../asset/AccessoriesThumb/6.png";
import accessories7 from "../asset/AccessoriesThumb/7.png";

import clothing1 from "../asset/ClothingThumb/1.png";
import clothing2 from "../asset/ClothingThumb/2.png";
import clothing3 from "../asset/ClothingThumb/3.png";
import clothing4 from "../asset/ClothingThumb/4.png";
import clothing5 from "../asset/ClothingThumb/5.png";
import clothing6 from "../asset/ClothingThumb/6.png";
import clothing7 from "../asset/ClothingThumb/7.png";

import bg1 from "../asset/Backgrounds/1.png";
import bg2 from "../asset/Backgrounds/2.png";
import bg3 from "../asset/Backgrounds/3.png";
import bg4 from "../asset/Backgrounds/4.png";
import bg5 from "../asset/Backgrounds/5.png";
import bg6 from "../asset/Backgrounds/6.png";
import bg7 from "../asset/Backgrounds/7.png";

const accessoriesImages = [
  accessories1,
  accessories2,
  accessories3,
  accessories4,
  accessories5,
  accessories6,
  accessories7,
];

const clothingImages = [
  clothing1,
  clothing2,
  clothing3,
  clothing4,
  clothing5,
  clothing6,
  clothing7,
];

const bgsImages = [bg1, bg2, bg3, bg4, bg5, bg6, bg7];

export default function CreateMeBoxes({
  variant,
  activeIndex,
  setActiveIndex,
}: {
  variant: CreateMeVariants;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}) {
  const array =
    variant === CreateMeVariants.BG
      ? bgsImages
      : variant === CreateMeVariants.CLOTHING
      ? clothingImages
      : accessoriesImages;

  return (
    <div className="boxes">
      {array.map((_, index) => (
        <div
          className={`box ${activeIndex === index ? "active" : ""}`}
          key={index}
          onClick={() => setActiveIndex(index)}
        >
          <img src={array[index]} alt={variant} />
        </div>
      ))}
    </div>
  );
}
