import header from "../asset/Header.jpg";
import logo from "../asset/Logo.jpg";
import insta from "../asset/InstagramLogo.webp";
import tiktok from "../asset/tiktokLogo.webp";
import pump from "../asset/PumpLogo.webp";
import yt from "../asset/YouTubLogo.webp";
import { Links } from "../const/links";

const QuickLinks = [
  {
    name: "Create a Me",
    link: "#",
    target: "_self",
  },
  {
    name: "About Me",
    link: "#about",
    target: "_self",
  },
  {
    name: "My Story",
    link: "#story",
    target: "_self",
  },
  {
    name: "TikTok",
    link: Links.tiktok,
    target: "_blank",
  },
  {
    name: "Telegram",
    link: Links.telegram,
    target: "_blank",
  },
  {
    name: "X",
    link: Links.x,
    target: "_blank",
  },
  {
    name: "Pump",
    link: Links.pump,
    target: "_blank",
  },
];

export default function Header() {
  return (
    <div className="header">
      <div className="quick-links">
        {QuickLinks.map((link) => (
          <a
            key={link.name}
            href={link.link}
            target={link.target}
            className="quick-link"
          >
            {link.name}
          </a>
        ))}
      </div>
      <div className="center">
        <img src={header} alt="header" className="header-image" />
        <div className="header-grid">
          <img
            onClick={() => window.open(Links.insta, "_blank")}
            src={insta}
            alt="insta"
            className="header-icon"
          />
          <img
            onClick={() => window.open(Links.tiktok, "_blank")}
            src={tiktok}
            alt="tiktok"
            className="header-icon"
          />
          <img
            onClick={() => window.open(Links.pump, "_blank")}
            src={pump}
            alt="pump"
            className="header-icon"
          />
          <img
            onClick={() => window.open(Links.yt, "_blank")}
            src={yt}
            alt="yt"
            className="header-icon"
          />
        </div>
      </div>
      <img src={logo} alt="logo" className="logo-image" />
    </div>
  );
}
